<script lang="ts" setup>
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';
import {loadAssetsFonts, loadProject, useSession} from '/src/core/session';
import {toRef} from 'vue';
import {Project, ProjectScreen, getProjectWithScreens} from '/src/api/projects';
import router from '/src/router';
import FuiEditor from '/src/components/fui/FuiEditor.vue';
import Presenter from '/src/components/fui/Presenter.vue';
import MobileIntro from '/src/components/layout/MobileIntro.vue';
import FuiLayers from '/src/components/fui/FuiLayers.vue';
import ScreensList from '/src/components/screens/ScreensList.vue';
import ProjectTitle from '/src/components/projects/ProjectTitle.vue';
import EditorButtons from '/src/components/layout/EditorButtons.vue';

const session = useSession();
const {state, setIsPublic} = session;
const {platform, auth, display} = toRefs(state);
const currenProject = ref({} as Project);
const currentScreen = ref({} as ProjectScreen);
const isScreenLoaded = ref(false);
const isSandboxModalOpen = ref(false);
const isScreenNotFound = ref(false);
const readonly = true;
const infoMessage = ref();
const errorMessage = ref();

const route = useRoute();
const isPresenterVisible = ref(false);

// computed
const project_id = computed(() => parseInt(route.params.project_id as string));
const screen_id = computed(() => parseInt(route.params.screen_id as string));
const isEmpty = computed(() => session.state.layers.length === 0);

watch(
    () => route.params.screen_id,
    async (newScreenId) => {
        isScreenNotFound.value = false;
        isScreenLoaded.value = false;
        await openProject(project_id.value, parseInt(newScreenId as string));
        isScreenLoaded.value = true;
    }
);

const emit = defineEmits(['showModal']);

// methods

async function openProject(project_id, screen_id) {
    if (!project_id) {
        currenProject.value = {
            id: 0,
            title: '',
            screens: [{id: 0}],
            platform: platform.value,
            screen_x: display.value.x,
            screen_y: display.value.y,
            private: true,
        };
        return;
    }

    const projectData = await getProjectWithScreens(project_id);
    if (!projectData) {
        router.push(auth.value ? '/' : '/sandbox');
        return;
    }
    currenProject.value = projectData;
    let screen;
    if (!screen_id) {
        screen = projectData.screens[0];
        !!screen && router.push(`/editor/${project_id}/${screen.id}`);
    } else {
        screen = projectData.screens?.find((item) => item.id === screen_id);
    }
    if (!screen) {
        isScreenNotFound.value = true;
    } else {
    }
    currentScreen.value = await loadProject(projectData, screen);
}

function update() {
    openProject(project_id.value, screen_id.value);
}

function openPresenter() {
    isPresenterVisible.value = true;
}

function closePresenter() {
    isPresenterVisible.value = false;
}

function setInfoMessage(msg) {
    infoMessage.value = msg;
    setTimeout(() => {
        infoMessage.value = null;
    }, 3000);
}
function setErrorMessage(msg) {
    errorMessage.value = msg;
    setTimeout(() => {
        errorMessage.value = null;
    }, 4000);
}

onMounted(async () => {
    await loadAssetsFonts(project_id.value);
    await openProject(project_id.value, screen_id.value);
    isScreenLoaded.value = true;
    const isSandboxShown = localStorage.getItem('sandboxModalShown');
    isSandboxModalOpen.value = !auth.value && isScreenLoaded.value && !isSandboxShown;
    setIsPublic(true);
});
</script>

<template>
    <MobileIntro></MobileIntro>
    <FuiEditor
        :project="currenProject"
        :screen="currentScreen"
        :isScreenLoaded="isScreenLoaded"
        :isScreenNotFound="isScreenNotFound"
        readonly
        @openPresenter="openPresenter"
    >
        <template #messages>
            <div
                class="alert alert-warning"
                v-if="errorMessage"
            >
                <span>{{ errorMessage }}</span>
            </div>
            <div
                class="alert alert-success"
                v-if="infoMessage"
            >
                <span>{{ infoMessage }}</span>
            </div>
        </template>
        <template #left>
            <ScreensList
                v-if="readonly || auth || isScreenNotFound"
                flow="col"
                :project="currenProject"
                :platform="platform"
                :readonly="readonly"
            />
        </template>
        <template #title>
            <div class="pb-1 flex justify-between">
                <ProjectTitle
                    full
                    :project="currenProject"
                    :screen="currentScreen"
                    :readonly="readonly"
                    @update="update"
                />
                <EditorButtons
                    :project="currenProject"
                    :screen="currentScreen"
                    readonly
                    @setErrorMessage="setErrorMessage"
                    @setInfoMessage="setInfoMessage"
                    @openPresenter="openPresenter"
                />
            </div>
        </template>
    </FuiEditor>
    <Presenter
        v-if="isPresenterVisible"
        :screens="currenProject.screens"
        @close="closePresenter"
    />
</template>
