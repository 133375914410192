if settings.include_fonts && declarations.some(decl => decl.type == 'font')
    | font:
    |
    each decl in declarations 
        if decl.type == 'font' && decl.data.format == '2'
            |   - file: "gfonts://!{decl.data.value.replace('_', '+')}"
            |     id: !{decl.data.value}
            |     size: !{decl.data.size}
            |
        if decl.type == 'font' && decl.data.format == '1'
            |   # You may need to download this font and put it into esphome folder
            |   - file: "fonts/!{decl.data.value}.bdf"
            |     id: !{decl.data.name}
            |
    |
    |
if settings.include_images && declarations.some(decl => decl.type == 'bitmap')
    | # You may need to download images and put them into esphome folder
    | image:
    |
    each decl in declarations 
        if decl.type == 'bitmap'
            |   - file: "images/!{decl.data.name}.png"
            |     id: !{decl.data.name}
            |     resize: !{decl.data.size}
            |     type: TRANSPARENT_BINARY
            |
    |
    |
- var screenBgColor = parseInt(screenBgColor.substr(1, 2), 16) + ',' + parseInt(screenBgColor.substr(3, 2), 16) + ',' + parseInt(screenBgColor.substr(5, 2), 16)
- var pad = settings.wrap ? '      ' : ''
if settings.wrap
    | display:
    |     lambda: |-
    |
- var font = ''
each layer in layers 
    if settings.comments
        | !{pad}// !{layer.name}
        |
    - var layerColor = parseInt(layer.color.substr(1, 2), 16) + ',' + parseInt(layer.color.substr(3, 2), 16) + ',' + parseInt(layer.color.substr(5, 2), 16)
    case layer.type
        when 'line'
            | @!{layer.uid};!{pad}it.line(@x1:!{layer.p1[0]}, @y1:!{layer.p1[1]}, @x2:!{layer.p2[0]}, @y2:!{layer.p2[1]}, Color(!{layerColor}));
        when 'rect'
            - var func = layer.fill ? 'filled_rectangle' : 'rectangle'
            | @!{layer.uid};!{pad}it.!{func}(@x:!{layer.position[0]}, @y:!{layer.position[1]}, @w:!{layer.size[0]}, @h:!{layer.size[1]}, Color(!{layerColor}));
        when 'circle'
            - var func = layer.fill ? 'filled_circle' : 'circle'
            | @!{layer.uid};!{pad}it.!{func}(@x:!{layer.position[0] + layer.radius}, @y:!{layer.position[1] + layer.radius}, @r:!{layer.radius}, Color(!{layerColor}));
        when 'ellipse'
            - var func = layer.fill ? 'filled_ellipse' : 'ellipse'
            | @!{layer.uid};!{pad}it.!{func}(@x:!{layer.position[0] + layer.rx}, @y:!{layer.position[1] + layer.ry}, @rx:!{layer.rx}, @ry:!{layer.ry}, Color(!{layerColor}));
        when 'string'
            | @!{layer.uid};!{pad}it.print(!{getTextPosition(layer).join(', ')}, id(!{layer.fontName}), Color(!{layerColor}), @text:"!{layer.text}");
        when 'paint'
        when 'icon'
            | @!{layer.uid};!{pad}it.image(@x:!{layer.position[0]}, @y:!{layer.position[1]}, @image:id(!{layer.imageName}), Color(!{layerColor}));
        default 
            |
            | // Unknown layer type !{layer.type}
            |
    |
    |
    |
|
