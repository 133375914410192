<script lang="ts" setup>
import {computed, ref} from 'vue';
import {Project, ProjectScreen} from '/src/api/projects';
import {cloneScreen, deleteScreen, updateScreen} from '/src/api/screens';
import ScreenActions from '/src/components/screens/ScreenActions.vue';
import Icon from '/src/components/layout/Icon.vue';
import {useRoute} from 'vue-router';
import router from '/src/router';
import {logEvent} from '/src/utils';
import TextEditable from '../layout/TextEditable.vue';

const props = defineProps<{
    screen: ProjectScreen;
    backgroundColor: string;
    project?: Project;
    isSubscriptionActive?: boolean;
    isDuplicateEnabled?: boolean;
    isActive?: boolean;
    readonly?: boolean;
}>();

const emit = defineEmits(['showModalPricing']);

const linkPath = computed(() => (props.readonly ? 'gallery' : 'editor'));

const screenTitle = ref();

const route = useRoute();

async function removeScreen() {
    await deleteScreen(props.screen.id);
    if (props.screen.id === parseInt(route.params.screen_id as string)) {
        router.push('/');
    }
    props.project.screens = props.project.screens.filter((item) => item.id !== props.screen.id);
    logEvent('button_screen_remove');
}

async function copyScreen() {
    const newScreen = await cloneScreen(props.screen.id);
    props.project.screens.push(newScreen);
    logEvent('button_screen_clone');
}

async function updateScreenTitle(title) {
    await updateScreen({
        id: props.screen.id,
        title,
    });
    props.screen.title = title;
    logEvent('button_screen_rename');
}

function renameScreen() {
    screenTitle.value.editScreenName();
}

function makePrivate() {
    updateScreen({id: props.screen.id, private: true});
    props.screen.private = true;
    logEvent('button_screen_private');
}

function makePublic() {
    updateScreen({id: props.screen.id, private: false});
    props.screen.private = false;
    logEvent('button_screen_publish');
}

function showModalPricing() {
    emit('showModalPricing');
}
</script>

<template>
    <div class="pt-2">
        <div
            class="screen-list-item relative h-24 mb-1 border border-base-300 w-fit"
            :class="{
                'border-primary border-2': isActive,
                'border-base-300': !isActive,
            }"
        >
            <div
                class="absolute -top-1 -right-1 dropdown"
                v-if="!readonly"
            >
                <button
                    tabindex="0"
                    role="button"
                    class="btn btn-circle btn-xs cursor-pointer screen-list-button invisible"
                >
                    <Icon
                        type="ellipsis"
                        lg
                        primary
                    />
                </button>
                <div
                    class="dropdown-content absolute -top-9 -left-9 z-10 p-10"
                    tabindex="0"
                >
                    <ScreenActions
                        :id="screen.id"
                        :screen="screen"
                        :isSubscriptionActive="isSubscriptionActive"
                        :isDuplicateEnabled="isDuplicateEnabled"
                        @removeScreen="removeScreen"
                        @copyScreen="copyScreen"
                        @renameScreen="renameScreen"
                        @makePrivate="makePrivate"
                        @makePublic="makePublic"
                        @showModalPricing="showModalPricing"
                    />
                </div>
            </div>
            <router-link :to="`/${linkPath}/${project.id}/${screen.id}`">
                <img
                    :src="screen.img_preview"
                    class="self-start screen-image object-cover w-full mx-auto min-w-16"
                    :style="{
                        backgroundColor: backgroundColor,
                    }"
                />
            </router-link>
        </div>
        <div class="flex flex-row items-center">
            <div
                v-if="!screen.private"
                class="tooltip tooltip-bottom"
                :data-tip="project.private ? `Hidden in project settings` : `Public`"
            >
                <div
                    class="w-2 h-2 rounded-full mr-1 border border-black"
                    :class="{
                        'bg-info': project.private,
                        'bg-success': !project.private,
                    }"
                ></div>
            </div>

            <TextEditable
                :text="screen.title"
                @update="updateScreenTitle"
                class="text-sm h-5 w-24"
                :readonly="readonly"
            />
        </div>
    </div>
</template>
<style lang="css" scoped>
.screen-list-item:hover .screen-list-button {
    visibility: visible;
}
</style>
