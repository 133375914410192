<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';
import {addCustomImage, loadAssetsFonts, loadProject, useSession} from '/src/core/session';
import {Project, ProjectScreen, getProjectWithScreens} from '/src/api/projects';
import router from '/src/router';
import ModalPricing from '/src/components/pricing/ModalPricing.vue';
import FuiEditor from '../fui/FuiEditor.vue';
import Presenter from '../fui/Presenter.vue';
import {getAssetsByProjectId} from '/src/api/assets';
import {loadImageAsync, logEvent} from '/src/utils';
import MobileIntro from './MobileIntro.vue';
import FuiLayers from '/src/components/fui/FuiLayers.vue';
import ScreensList from '/src/components/screens/ScreensList.vue';
import ProjectTitle from '/src/components/projects/ProjectTitle.vue';
import EditorButtons from '/src/components/layout/EditorButtons.vue';

const session = useSession();
const route = useRoute();

const {state, setIsPublic} = session;
const {platform, auth, display} = toRefs(state);
const currenProject = ref({} as Project);
const currentScreen = ref({} as ProjectScreen);
const isScreenLoaded = ref(false);
const isSandboxModalOpen = ref(false);
const isPricesVisible = ref(false);
const isScreenNotFound = ref(false);
const isSandbox = ref(false);
const isPresenterVisible = ref(false);
const readonly = false;
const infoMessage = ref();
const errorMessage = ref();

// computed
const project_id = computed(() => parseInt(route.params.project_id as string));
const screen_id = computed(() => parseInt(route.params.screen_id as string));
const isEmpty = computed(() => session.state.layers.length === 0);

watch(
    () => route.params.screen_id,
    async (newScreenId) => {
        isScreenNotFound.value = false;
        isScreenLoaded.value = false;
        await openProject(project_id.value, parseInt(newScreenId as string));
        isScreenLoaded.value = true;
    }
);

const emit = defineEmits(['showModal']);

// methods

async function openProject(project_id, screen_id) {
    if (!project_id) {
        currenProject.value = {
            id: 0,
            title: '',
            screens: [{id: 0}],
            platform: platform.value,
            screen_x: display.value.x,
            screen_y: display.value.y,
            private: true,
        };
        return;
    }

    const projectData = await getProjectWithScreens(project_id);
    if (!projectData || projectData.user_id !== auth.value?.user?.id) {
        if (!auth.value) {
            router.push('/sandbox');
            session.initSandbox();
        } else {
            router.push('/');
        }
        return;
    }
    currenProject.value = projectData;
    let screen;
    if (!screen_id) {
        screen = projectData.screens[0];
        !!screen && router.push(`/editor/${project_id}/${screen.id}`);
    } else {
        screen = projectData.screens?.find((item) => item.id === screen_id);
    }
    if (!screen) {
        isScreenNotFound.value = true;
    }
    currentScreen.value = await loadProject(projectData, screen);
}

function showModalPricing() {
    isPricesVisible.value = true;
}

function closeModalPricing() {
    isPricesVisible.value = false;
}

function update() {
    openProject(project_id.value, screen_id.value);
}

function closeSandboxModal() {
    isSandboxModalOpen.value = false;
    localStorage.setItem('sandboxModalShown', 'true');
}

async function loadAssetsImages() {
    const assets = await getAssetsByProjectId(project_id.value, 'image');
    if (!assets) return;
    const assetPromises = assets.map(async (asset) => {
        await loadImageAsync(asset.url).then((image) => {
            const {width, height} = image;
            addCustomImage(asset.filename, width, height, image, false, asset.id);
        });
    });
    return Promise.all(assetPromises);
}

function openPresenter() {
    isPresenterVisible.value = true;
}

function closePresenter() {
    isPresenterVisible.value = false;
}

function setInfoMessage(msg) {
    infoMessage.value = msg;
    setTimeout(() => {
        infoMessage.value = null;
    }, 3000);
}
function setErrorMessage(msg) {
    errorMessage.value = msg;
    setTimeout(() => {
        errorMessage.value = null;
    }, 4000);
}

onMounted(async () => {
    session.state.customImages = [];
    session.state.customFonts = [];
    isScreenLoaded.value = false;
    if (router.currentRoute.value.path === '/sandbox') {
        isScreenLoaded.value = true;
        isSandbox.value = true;
        session.initSandbox();
        !auth.value &&
            setTimeout(() => {
                isSandboxModalOpen.value = true;
                logEvent('sandbox_modal_show');
            }, 7000);
    } else {
        await loadAssetsFonts(project_id.value);
        loadAssetsImages();
    }
    await openProject(project_id.value, screen_id.value);
    isScreenLoaded.value = true;
    setIsPublic(false);
});
</script>

<template>
    <MobileIntro></MobileIntro>
    <div class="flex flex-col flex-grow">
        <dialog
            id="sandbox_modal"
            class="modal modal-open"
            v-if="isSandboxModalOpen && isSandbox"
        >
            <div class="modal-box border border-primary -mt-32">
                <button
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    @click="closeSandboxModal"
                >
                    ✕
                </button>
                <!-- <h3 class="text-xl font-bold">Lopaka.app Editor</h3> -->
                <p class="pb-2 text-primary text-lg">Welcome to Lopaka Sandbox!</p>
                <p class="py-2">
                    It's recommended to
                    <b>Sign In</b>
                    to save your Projects for future use and unlock full access to Lopaka's features.
                    <span class="text-info">Otherwise you can lose your work.</span>
                </p>
                <div class="text-center pb-1">
                    <router-link
                        to="/login"
                        class="btn btn-sm btn-success"
                        @click="logEvent('sandbox_modal_signin')"
                    >
                        Sign In
                    </router-link>
                </div>
            </div>
        </dialog>
        <FuiEditor
            :project="currenProject"
            :screen="currentScreen"
            :isScreenLoaded="isScreenLoaded"
            :isScreenNotFound="isScreenNotFound"
            :isSandbox="isSandbox"
            :auth="!!auth"
            @showModalPricing="showModalPricing"
            @openPresenter="openPresenter"
            @setErrorMessage="setErrorMessage"
            @setInfoMessage="setInfoMessage"
        >
            <template #messages>
                <div
                    class="alert alert-warning"
                    v-if="errorMessage"
                >
                    <span>{{ errorMessage }}</span>
                </div>
                <div
                    class="alert alert-success"
                    v-if="infoMessage"
                >
                    <span>{{ infoMessage }}</span>
                </div>
            </template>
            <template #left>
                <div
                    role="tablist"
                    class="tabs tabs-bordered"
                >
                    <input
                        type="radio"
                        name="editor_tabs_1"
                        role="tab"
                        class="tab font-sans"
                        aria-label="Layers"
                        checked
                    />
                    <div
                        role="tabpanel"
                        class="tab-content pt-2"
                    >
                        <div v-if="!isScreenLoaded && !isScreenNotFound">
                            <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                            <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                            <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                            <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                            <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                        </div>
                        <FuiLayers
                            v-else
                            v-show="!isEmpty"
                            :readonly="readonly"
                        ></FuiLayers>
                    </div>
                    <input
                        type="radio"
                        name="editor_tabs_1"
                        role="tab"
                        class="tab font-sans"
                        aria-label="Screens"
                    />
                    <div
                        role="tabpanel"
                        class="tab-content pt-2"
                    >
                        <ScreensList
                            v-if="readonly || auth || isScreenNotFound"
                            flow="col"
                            @showModalPricing="showModalPricing"
                            :project="currenProject"
                            :platform="platform"
                            :readonly="readonly"
                        />
                        <div
                            v-if="!auth && !readonly"
                            class="text-center py-2"
                        >
                            Please
                            <router-link
                                class="link link-primary"
                                to="/login"
                            >
                                Sign In
                            </router-link>
                            to create Screens and manage Projects
                        </div>
                    </div>
                </div>
            </template>
            <template #title>
                <div class="pb-1 flex justify-between">
                    <ProjectTitle
                        v-if="auth || readonly"
                        full
                        :project="currenProject"
                        :screen="currentScreen"
                        :readonly="readonly"
                        @update="update"
                    />
                    <EditorButtons
                        :project="currenProject"
                        :screen="currentScreen"
                        :readonly="readonly"
                        @setErrorMessage="setErrorMessage"
                        @setInfoMessage="setInfoMessage"
                        @openPresenter="openPresenter"
                    />
                </div>
            </template>
        </FuiEditor>
        <ModalPricing
            v-if="isPricesVisible"
            @close="closeModalPricing"
            canBeClosed
        />
        <Presenter
            v-if="isPresenterVisible"
            :screens="currenProject.screens"
            @close="closePresenter"
        />
    </div>
</template>
