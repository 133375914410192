<script lang="ts" setup>
import {computed, toRefs} from 'vue';
import {forkProject, Project, ProjectScreen, toggleProjectStar} from '/src/api/projects';
import {useSession} from '/src/core/session';
import {canvasToBMPFile, logEvent, toCppVariableName} from '/src/utils';
import router from '/src/router';
import Button from '/src/components/layout/Button.vue';
import Icon from '/src/components/layout/Icon.vue';
import {downloadAsFile} from '/src/utils';

const props = defineProps<{
    project: Project | null;
    screen: ProjectScreen | null | undefined;
    readonly: boolean;
}>();

const session = useSession();
const {state} = session;
const {auth, subscription} = toRefs(state);

const emit = defineEmits(['setInfoMessage', 'setErrorMessage', 'openPresenter']);

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');
const publishTooltip = computed(() => {
    if (props.project?.private && props.screen?.private) {
        return 'You need to publish both Project and Screen';
    } else if (props.project?.private) {
        return 'Publish in Project settings first';
    } else if (props.screen?.private) {
        return 'Publish the Screen first';
    }
    return '';
});

function copyPublicLink() {
    const hostname = window.location.origin;
    const link = `${hostname}/gallery/${props.project.id}/${props.screen.id}`;
    navigator.clipboard.writeText(link).then(() => {
        emit('setInfoMessage', 'Link was copied to clipboard');
    });
    logEvent('button_share');
}

async function cloneProject() {
    if (!auth.value) {
        router.push('/login');
        return;
    }
    logEvent('button_fork');
    const {data, error} = await forkProject(props.project.id);
    if (error) {
        emit('setErrorMessage', error.message);
    } else {
        const {project_id, screen_id} = data;
        router.push(`/editor/${project_id}/${screen_id}`);
        emit('setInfoMessage', 'Project was successfully cloned!');
    }
}

async function toggleStar(projectId: number) {
    if (!auth.value) {
        router.push('/login');
        return;
    }
    await toggleProjectStar(projectId);

    // Update star status in local projects array
    if (props.project) {
        props.project.is_starred = !props.project.is_starred;
        props.project.stars_count += props.project.is_starred ? 1 : -1;
    }
}

function present() {
    if (!isSubscriptionActive.value) return;
    emit('openPresenter');
}
function saveImage(type) {
    if (!isSubscriptionActive.value) return;

    const screenName = props.screen?.title || 'screen';
    const projectName = props.project?.title || 'project';
    const filename = `${projectName}-${screenName}.${type}`;

    const scaling = Math.max(1, session.state.scale.x);

    if (type === 'bmp') {
        const bmpBlob = canvasToBMPFile(
            session.virtualScreen.canvas,
            scaling,
            session.getPlatformFeatures().screenBgColor
        );
        downloadAsFile(filename, bmpBlob, 'blob');
    } else {
        // Create a temporary canvas for scaling
        const tempCanvas = document.createElement('canvas');
        const sourceCanvas = session.virtualScreen.canvas;
        tempCanvas.width = sourceCanvas.width * scaling;
        tempCanvas.height = sourceCanvas.height * scaling;
        const ctx = tempCanvas.getContext('2d');

        // Fill canvas with black background first
        ctx.fillStyle = session.getPlatformFeatures().screenBgColor;
        ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

        ctx.imageSmoothingEnabled = false;
        ctx.scale(scaling, scaling);
        ctx.drawImage(sourceCanvas, 0, 0);

        downloadAsFile(filename, tempCanvas.toDataURL(`image/${type}`), 'dataurl');
    }

    emit('setInfoMessage', 'Image saved successfully');
    logEvent(`button_save_${type}`);
}

function saveText() {
    if (!isSubscriptionActive.value) return;

    const sourceCode = session.generateCode().code;
    const screenName = props.screen?.title || 'screen';
    const filename = toCppVariableName(`${screenName}`) + `.c`;
    const blob = new Blob([sourceCode], {type: 'text/plain'});

    downloadAsFile(filename, blob, 'blob');

    emit('setInfoMessage', 'Source code saved successfully');
    logEvent('button_save_text');
}
</script>

<template>
    <div
        class="flex gap-2 mr-2"
        v-if="auth || readonly"
    >
        <div class="flex gap-2">
            <div
                class="tooltip tooltip-bottom capitalize"
                :data-tip="project.is_starred ? `Stars: ${project.stars_count ?? 0}` : `Add to favourites`"
            >
                <button
                    v-if="project.is_starred"
                    class="flex badge border-secondary w-8 h-8 px-0"
                    @click.prevent="toggleStar(project.id)"
                >
                    <Icon
                        v-if="project.is_starred"
                        type="star-solid"
                        primary
                    />
                </button>
                <button
                    v-else
                    class="flex badge border-secondary w-8 h-8 px-0"
                    @click.prevent="toggleStar(project.id)"
                >
                    <Icon :type="project.is_starred ? 'star-solid' : 'star'" />
                </button>
            </div>
        </div>
        <div class="flex gap-2">
            <Button
                :title="auth ? `Have a copy of this project` : `Sign in to fork this project`"
                @click="cloneProject"
            >
                <Icon type="fork" />
                <span class="lg:hidden xl:hidden 2xl:inline">Fork</span>
            </Button>
        </div>
        <div class="dropdown">
            <div
                tabindex="0"
                role="button"
                class="btn btn-sm font-sans uppercase leading-none btn-primary btn-outline"
            >
                <Icon type="share" />
                <span class="lg:hidden xl:hidden 2xl:inline">Share</span>
            </div>
            <ul
                tabindex="0"
                class="dropdown-content menu menu-sm bg-secondary rounded rounded-xl z-[10] p-2 w-40"
            >
                <li v-if="!project.private && !screen?.private">
                    <a @click="copyPublicLink">Copy public link</a>
                </li>
                <li
                    v-else
                    class="tooltip disabled"
                    :data-tip="publishTooltip"
                >
                    <div class="pointer-events-none">
                        <span class="text-nowrap">Copy public link</span>
                    </div>
                </li>
                <li
                    class="tooltip tooltip-bottom"
                    :class="{disabled: !isSubscriptionActive}"
                    :data-tip="isSubscriptionActive ? `` : `Upgrade to Plus to enable presentation mode`"
                >
                    <a @click="present">
                        Present
                        <div
                            class="bg-success text-black badge cursor-pointer"
                            v-if="!isSubscriptionActive"
                            @click.prevent="
                                router.push('/price');
                                logEvent('button_pro', 'share_save_as');
                            "
                        >
                            <span class="font-bold">Plus</span>
                        </div>
                    </a>
                </li>
                <li>
                    <details>
                        <summary class="text-nowrap">
                            Save As
                            <div
                                class="bg-success text-black badge cursor-pointer"
                                v-if="!isSubscriptionActive"
                                @click.prevent="
                                    router.push('/price');
                                    logEvent('button_pro', 'share_save_as');
                                "
                            >
                                <span class="font-bold">Plus</span>
                            </div>
                        </summary>
                        <div
                            class="tooltip tooltip-bottom"
                            :data-tip="isSubscriptionActive ? `` : `Upgrade to Plus to enable file export`"
                        >
                            <ul>
                                <li :class="{disabled: !isSubscriptionActive}">
                                    <a
                                        @click="saveText"
                                        class="text-nowrap"
                                        :class="{'pointer-events-none': !isSubscriptionActive}"
                                    >
                                        Text .C file
                                    </a>
                                </li>
                                <li :class="{disabled: !isSubscriptionActive}">
                                    <a
                                        @click="saveImage('png')"
                                        class="text-nowrap"
                                        :class="{'pointer-events-none': !isSubscriptionActive}"
                                    >
                                        PNG image
                                    </a>
                                </li>
                                <li :class="{disabled: !isSubscriptionActive}">
                                    <a
                                        @click="saveImage('bmp')"
                                        class="text-nowrap"
                                        :class="{'pointer-events-none': !isSubscriptionActive}"
                                    >
                                        BMP image
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </details>
                </li>
            </ul>
        </div>
    </div>
</template>
<style></style>
