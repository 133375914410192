<script lang="ts" setup>
import {computed, toRefs} from 'vue';
import Button from '/src/components/layout/Button.vue';
import ScreenPreview from '/src/components/screens/ScreenPreview.vue';
import {Project} from '/src/api/projects';
import {createScreen} from '/src/api/screens';
import {useSession} from '/src/core/session';
import {useRoute} from 'vue-router';
import {logEvent} from '/src/utils';

const props = defineProps<{
    flow: string;
    project: Project;
    platform: string;
    readonly?: boolean;
}>();

const session = useSession();
const {state} = session;
const {subscription} = toRefs(state);
const route = useRoute();

const route_screen_id = computed(() => parseInt(route.params.screen_id as string));

const classList = computed(() => ({
    'gap-2 flex-col': props.flow === 'col',
    'gap-4 flex-row items-center': props.flow === 'row',
}));

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');
const isNewScreens = computed(
    () => isSubscriptionActive.value || props.project?.screens?.length < 3 || !props.project?.screens
);

const emit = defineEmits(['showModalPricing', 'createScreen']);

function showModalPricing() {
    emit('showModalPricing');
}

async function addScreen() {
    if (isNewScreens.value) {
        const index = props.project.screens ? props.project.screens.length + 1 : '1';
        const newScreen = await createScreen(props.project, `Screen ${index}`);
        props.project.screens ? props.project.screens.push(newScreen) : (props.project.screens = [newScreen]);
        logEvent('button_screen_add');
    } else {
        emit('showModalPricing');
    }
}
</script>
<template>
    <div
        class="flex flex-wrap content-center"
        :class="classList"
    >
        <div
            v-for="screen in project.screens?.filter((item) => (readonly ? item.private === false : true))"
            :key="screen.id"
        >
            <ScreenPreview
                :screen="screen"
                :backgroundColor="project.color_bg ?? session.getPlatformFeatures(platform).screenBgColor"
                :project="project"
                :isSubscriptionActive="isSubscriptionActive"
                :isDuplicateEnabled="isNewScreens"
                :isActive="route_screen_id === screen.id"
                :readonly="readonly"
                @showModalPricing="showModalPricing"
            />
        </div>
        <div
            class="flex pb-8 justify-center"
            v-if="!readonly"
        >
            <Button
                @click="addScreen()"
                variant="ghost"
                title="Add new screen"
            >
                +
                <div
                    v-if="!isNewScreens"
                    class="bg-success text-secondary text-xs capitalize px-1 rounded-full absolute -top-2 -right-4"
                >
                    Plus
                </div>
            </Button>
        </div>
    </div>
</template>
<style lang="css">
.screen-image {
    image-rendering: pixelated;
    height: 100%;
    width: auto;
}
</style>
