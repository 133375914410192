import {Point} from './point';

export type Display = {
    title: string;
    size: Point;
};

export default [
    {title: '32×8', size: new Point(32, 8)},
    {title: '32×16', size: new Point(32, 8)},
    {title: '32×32', size: new Point(32, 8)},
    {title: '32×64', size: new Point(32, 8)},
    {title: '64×32', size: new Point(64, 32)},
    {title: '64×48', size: new Point(64, 48)},
    {title: '64×128', size: new Point(64, 128)},
    {title: '72×40', size: new Point(72, 40)},
    {title: '84×48', size: new Point(84, 48)},
    {title: '96×16', size: new Point(96, 16)},
    {title: '96×32', size: new Point(96, 32)},
    {title: '96×40', size: new Point(96, 40)},
    {title: '96×96', size: new Point(96, 96)},
    {title: '128×32', size: new Point(128, 32)},
    {title: '128×36', size: new Point(128, 36)},
    {title: '128×64', size: new Point(128, 64)},
    {title: '128×80', size: new Point(128, 80)},
    {title: '128×96', size: new Point(128, 96)},
    {title: '128×128', size: new Point(128, 128)},
    {title: '128×160', size: new Point(128, 160)},
    {title: '160×32', size: new Point(160, 32)},
    {title: '160×80', size: new Point(160, 80)},
    {title: '160×132', size: new Point(160, 132)},
    {title: '160×160', size: new Point(160, 160)},
    {title: '192×32', size: new Point(192, 32)},
    {title: '192×64', size: new Point(192, 64)},
    {title: '192×96', size: new Point(192, 96)},
    {title: '200×200', size: new Point(200, 200)},
    {title: '240×64', size: new Point(240, 64)},
    {title: '240×128', size: new Point(240, 128)},
    {title: '240×160', size: new Point(240, 160)},
    {title: '240×240', size: new Point(240, 240)},
    {title: '250×122', size: new Point(250, 122)},
    {title: '256×128', size: new Point(256, 128)},
    {title: '256×32', size: new Point(256, 32)},
    {title: '256×64', size: new Point(256, 64)},
    {title: '280×240', size: new Point(280, 240)},
    {title: '296×128', size: new Point(296, 128)},
    {title: '320×200', size: new Point(320, 200)},
    {title: '320×240', size: new Point(320, 240)},
    {title: '400×240', size: new Point(400, 240)},
    {title: '480×320', size: new Point(480, 320)},
];
