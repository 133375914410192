import {getLayerProperties} from '../core/decorators/mapping';
import {AbstractImageLayer} from '../core/layers/abstract-image.layer';
import {AbstractLayer} from '../core/layers/abstract.layer';
import {TextLayer} from '../core/layers/text.layer';
import {bdfFonts, ttfFonts} from '../draw/fonts/fontTypes';
import {imgDataToXBMP, toCppVariableName} from '../utils';
import {Platform} from './platform';
import defaultTemplate from './templates/esphome/default.pug';
import {EsphomeParser} from './parsers/esphome.parser';
import {PlatformTemplates} from '/src/types';

export class EsphomePlatform extends Platform {
    public static id = 'esphome';
    protected name = 'ESPHome';
    protected description = 'ESPHome Display Component';
    protected fonts: TPlatformFont[] = [...ttfFonts];
    protected parser: EsphomeParser = new EsphomeParser();

    protected currentTemplate: string = 'default';

    protected templates: PlatformTemplates = {
        default: {
            name: 'ESPHome',
            template: defaultTemplate,
            settings: {
                wrap: true,
                comments: false,
                include_fonts: true,
                include_images: true,
            },
        },
    };

    constructor() {
        super();
        this.features.hasCustomFontSize = true;
        this.features.hasRGBSupport = true;
        this.features.defaultColor = '#FFFFFF';
        this.features.screenBgColor = '#000000';
    }

    generateSourceCode(layers: AbstractLayer[], ctx?: OffscreenCanvasRenderingContext2D): string {
        const declarations: {type: string; data: any}[] = [];
        const xbmps = [];
        const xbmpsNames = [];
        const layerData = layers
            .sort((a: AbstractLayer, b: AbstractLayer) => a.index - b.index)
            .map((layer) => {
                const props = getLayerProperties(layer);
                if (layer instanceof AbstractImageLayer) {
                    const XBMP = imgDataToXBMP(layer.data, 0, 0, layer.size.x, layer.size.y).join(',');
                    if (xbmps.includes(XBMP)) {
                        props.imageName = xbmpsNames[xbmps.indexOf(XBMP)];
                    } else {
                        const name = layer.imageName ?? toCppVariableName(layer.name);
                        declarations.push({
                            type: 'bitmap',
                            data: {
                                name: name,
                                size: layer.size.x + 'x' + layer.size.y,
                            },
                        });
                        xbmps.push(XBMP);
                        xbmpsNames.push(name);
                        props.imageName = name;
                    }
                } else if (layer instanceof TextLayer) {
                    // ESPHome uses system fonts or custom fonts defined in the YAML config
                    props.fontName = toCppVariableName(layer.font.name);
                    declarations.push({
                        type: 'font',
                        data: {
                            name: props.fontName,
                            value: layer.font.name,
                            size: layer.scaleFactor,
                            format: layer.font.format,
                        },
                    });
                }
                return props;
            });
        const source = this.templates[this.currentTemplate].template({
            declarations,
            layers: layerData,
            settings: Object.assign({}, this.settings, this.templates[this.currentTemplate].settings),
            getTextPosition: (layer) => this.getTextPosition(layer),
            screenBgColor: this.features.screenBgColor,
        });
        return source;
    }

    protected getTextPosition(layer: TextLayer) {
        return [layer.position[0], layer.position[1] - layer.bounds[3]];
    }
}
